import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { mediaQueries } from "./breakpoints"
import { css } from "@emotion/react"
import Img from 'gatsby-image'
import BtcPay from './BtcPay'

const Grid = styled("div")`
  display: grid;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem 2rem;
  ${mediaQueries.large(
    css`
      grid-template-columns: 1fr 1fr;
      gap: 1rem 4rem;
    `
  )}
`

const GridDonate = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 2rem;

  ${mediaQueries.small(
    css`
      flex-wrap: nowrap;
      gap: 2rem 4rem;
    `
  )}

  ${mediaQueries.large(
    css`
      justify-self: flex-end;
    `
  )}
`


const Hr = styled("hr")`
  margin-block: 2rem;
`

export default () => {
  const data = useStaticQuery(graphql`
    query sectionDonateQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "donate" } }) {
        html
        frontmatter {
          bank
          iban
          images {
            image {
              id
              childImageSharp {
                fluid(quality: 95, maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
          }
        }
      }
    }
  `)

  return (
    <div>
      <Grid>
        <div>
          {data.markdownRemark.html && (
            <div
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
            
          )}
          <p>Číslo účtu: <strong>{data.markdownRemark.frontmatter.bank}</strong><br />IBAN: <strong>{data.markdownRemark.frontmatter.iban}</strong><br /></p>
         </div>
        <GridDonate>
          <BtcPay />
          {data.markdownRemark.frontmatter.images && data.markdownRemark.frontmatter.images.map(image => (
            <Img style={{maxWidth: 250, width: 250}} fluid={image.image.childImageSharp.fluid} alt={image.alt} />
          ))}
        </GridDonate>
      </Grid>
    </div>
  )
}
