import React, { useRef, useEffect, useState } from "react"

export default function BtcPay() {
  const [price, setPrice] = useState(1000) // maintain a state for price
  const formRef = useRef(null)

  useEffect(() => {
    const handleSliderChange = event => {
      event.preventDefault()
      const newPrice = parseInt(event.target.value)
      const min = parseInt(event.target.getAttribute("min")) || 1
      const max = parseInt(event.target.getAttribute("max"))
      if (newPrice < min) {
        setPrice(min)
      } else if (newPrice > max) {
        setPrice(max)
      } else {
        setPrice(newPrice)
      }
    }

    const handleSliderInput = event => {
      setPrice(parseInt(event.target.value))
    }

    const formEl = formRef.current
    formEl.querySelectorAll(".btcpay-input-range").forEach(function (el) {
      if (!el.dataset.initialized) {
        el.addEventListener("input", handleSliderInput)
        el.dataset.initialized = true
      }
    })

    formEl.querySelectorAll(".btcpay-input-price").forEach(function (el) {
      if (!el.dataset.initialized) {
        el.addEventListener("change", handleSliderChange)
        el.dataset.initialized = true
      }
    })
  }, [])

  return (
    <div>
      {/* Put your CSS styles here */}
      <style>
        {`btcpay-form { display: inline-flex; align-items: center; justify-content: center; } .btcpay-form--inline { flex-direction: row; } .btcpay-form--block { flex-direction: column; max-width: 209px } .btcpay-form--inline .submit { margin-left: 15px; } .btcpay-form--block select { margin-bottom: 10px; } .btcpay-form .btcpay-custom-container{ text-align: center; }.btcpay-custom { display: flex; align-items: center; justify-content: center; } .btcpay-form .plus-minus { cursor:pointer; font-size:25px; line-height: 25px; background: #DFE0E1; height: 30px; width: 45px; border:none; border-radius: 60px; margin: auto 5px; display: inline-flex; justify-content: center; } .btcpay-form select { margin-top: 10px; color: currentColor; background: transparent; border: 1px solid white; display: block; padding: 5px; border-radius: 4px; margin-left: auto; margin-right: auto; font-size: 11px; cursor: pointer; } .btcpay-form select:hover { border-color: #ccc; } .btcpay-form option { color: #000; background: rgba(0,0,0,.1); } .btcpay-input-price { -moz-appearance: textfield; border: none; box-shadow: none; text-align: center; font-size: 25px; margin: auto; border-radius: 5px; line-height: 35px; background: #fff; }.btcpay-input-price::-webkit-outer-spin-button, .btcpay-input-price::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }`}
      </style>
      <form
        method="POST"
        action="https://pay.libinst.cz/api/v1/invoices"
        className="btcpay-form btcpay-form--block"
        ref={formRef}
      >
        <input
          type="hidden"
          name="storeId"
          value="3oCotqnAYVns7CcxE8WuH2TyjGbRaouhEkfhuhbddP2b"
        />
        <input type="hidden" name="checkoutDesc" value="Letní škola" />
        <input
          type="hidden"
          name="notifyEmail"
          value="letni.skola@libinst.cz"
        />
        <div className="btcpay-custom-container">
          <input
            className="btcpay-input-price"
            type="number"
            name="price"
            min="100"
            max="10000"
            step="100"
            value={price}
            data-price="1000"
            style={{ width: 209 }}
          />
          <select name="currency">
            <option value="CZK" selected>
              CZK
            </option>
            <option value="USD">USD</option>
            <option value="GBP">GBP</option>
            <option value="EUR">EUR</option>
            <option value="BTC">BTC</option>
          </select>
          <input
            type="range"
            className="btcpay-input-range"
            min="100"
            max="10000"
            step="100"
            value={price}
            style={{ width: 209, marginBottom: 15 }}
          />
        </div>
        <button
          type="submit"
          className="submit"
          name="submit"
          style={{
            minWidth: "209px",
            minHeight: "57px",
            borderRadius: "4px",
            borderStyle: "none",
            backgroundColor: "#0f3b21",
            cursor: "pointer",
          }}
          title="Pay with BTCPay Server, a Self-Hosted Bitcoin Payment Processor"
        >
          <span style={{ color: "#fff" }}>Platit s</span>
          <img
            src="https://pay.libinst.cz/img/paybutton/logo.svg"
            style={{
              height: "57px",
              display: "inline-block",
              padding: "5% 0 5% 5px",
              verticalAlign: "middle",
            }}
          />
        </button>
      </form>
    </div>
  )
}
