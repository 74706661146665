import React from "react"
import styled from "@emotion/styled"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import Button from "./Button"

const CTAButtons = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  flex-wrap: wrap;

  a {
    margin-top: .5rem;
    margin-bottom: .5rem;
    margin-left: calc(var(--gutterWidth) / 2);
    margin-right: calc(var(--gutterWidth) / 2);
  }
`

class CTA extends React.Component {

  render() {
    const { data} = this.props
    const { edges: events } = data.allMarkdownRemark

    return (
      <>
        {events && events.length ? (
          <>
            {events &&
              events.map(({ node: event }) => (
                <CTAButtons key={event.id}>
                  {event.frontmatter.event1formURL && (
                    <Button to={event.frontmatter.event1formURL}>PŘIHLÁSIT SE NA MALI</Button>
                  )}
                  {event.frontmatter.event2formURL && (
                    <Button to={event.frontmatter.event2formURL}>PŘIHLÁSIT SE NA MSLI</Button>
                  )}
                </CTAButtons>
              )
            )}
          </>
        ) : null }
      </>
    )
  }
}

CTA.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query CTAQuery {
        allMarkdownRemark(
          limit: 1
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              registrationStatus: { in: "open" }
              templateKey: { eq: "event" }
            }
          }
        ) {
          edges {
            node {
              id
              html
              frontmatter {
                event1formURL
                event2formURL
                registrationStatus
              }
            }
          }
        }
      }
    `}
    render={(data, count, className) => <CTA data={data} count={count} />}
  />
)
