import React from "react"
import { Container } from "./Container"
import { Section } from "./Section"
import Supporters from "./Supporters"
import Donate from "./Donate"

export default function PartnersSection() {
  return (
    <>
      <Section id="partneri">
        <Container about>
          <Supporters />
        </Container>

        <div style={{marginTop: "4rem"}}>
          <Container>
            <Donate />
          </Container>
        </div>
      </Section>
    </>
  )
}
