import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { mediaQueries } from "./breakpoints"
import { Container } from "./Container"
import { Section } from "./Section"


const Documents = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: var(--gutterWidth);
  grid-row-gap: var(--gutterWidth);
  ${mediaQueries.small(
    css`
       grid-template-columns: 1fr 1fr;
    `
  )}
  ${mediaQueries.large(
    css`
       grid-template-columns: 1fr 1fr 1fr;
    `
  )}
  h3 {
    font-size: var(--h4);
  }
`

const Document = styled("div")`

`

const DocumentParagraph = styled("p")`
  margin-bottom: 3rem;
  text-align: center;
`

const List = styled("ul")`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled("li")`
  margin-bottom: .5rem;
`

class SectionDocuments extends React.Component {

  render() {
    const { data } = this.props
    const { edges: events } = data.allMarkdownRemark

    return (
      <>
        {events && events.length ? (
          <>
            {events &&
              events.map(({ node: event }) => (
              <>
                {event.frontmatter.documents &&
                event.frontmatter.documents.length ? (
                  <Section id="podklady" key={event.id}>
                    <Container >
                      <h2>PODKLADY PRO MSLI</h2>
                      <DocumentParagraph>Seznam letošních textů k diskusi:</DocumentParagraph>
                      <Documents>
                        {event.frontmatter.documents.map(document => (
                          <Document key={document.id}>
                            {document.documentSpeaker && (
                              <>
                              <h3>{document.documentSpeaker}</h3>
                              <List>
                                {document.documentItems.map(documentItem => (
                                  <ListItem>
                                    <a target="_blank" rel="noopener noreferrer" href={documentItem.documentLink}>{documentItem.documentName}</a> {documentItem.documentAuthor && (
                                      <>
                                        <br/>
                                        ({documentItem.documentAuthor})
                                      </>
                                    )}
                                  </ListItem>
                                ))}
                              </List>
                              </>
                            )}

                          </Document>
                        ))}
                      </Documents>
                    </Container>
                  </Section>
                  ) : null }
                </>
              )
            )}
          </>
        ) : null }
      </>
    )
  }
}

SectionDocuments.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query SectionDocumentsQuery {
        allMarkdownRemark(
          limit: 1
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              registrationStatus: { in: [ "preparation", "open", "close", "postponed" ]}
              templateKey: { eq: "event" }
            }
          }
        ) {
          edges {
            node {
              id
              frontmatter {
                templateKey
                documents {
                  documentSpeaker
                  documentItems {
                    documentName
                    documentLink
                    documentAuthor
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <SectionDocuments data={data} count={count} />}
  />
)
