import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import styled from '@emotion/styled';

const AllYearsContent = styled("div")`
  padding: 2rem;
  background-color: var(--darkGray2);
`;

const AllYearsList = styled("ul")`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const AllYearsItem = styled("li")`
  margin-right: 1rem;
  margin-bottom: .5rem;
  a {
    color: #f9d83d;
    &:hover {
      text-decoration: none;
    }
  }
`;

class AllYears extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: events } = data.allMarkdownRemark;

    return (
      <AllYearsContent>
        <h3>Předchozí ročníky</h3>
        <AllYearsList>
          {events &&
            events.map(({ node: event }) => (
              <AllYearsItem key={event.id}>
                <Link to={event.fields.slug}>{event.frontmatter.title}</Link>
              </AllYearsItem>
            ))}
        </AllYearsList>
      </AllYearsContent>
    );
  }
}

AllYears.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query AllYearsQuery {
        allMarkdownRemark(
          limit: 1000
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              templateKey: { eq: "event" }
              registrationStatus: { eq: "past" }
            }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date
                registrationStatus
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <AllYears data={data} count={count} />}
  />
);
