import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { mediaQueries } from "./breakpoints"
import { css } from "@emotion/react"
import Img from "gatsby-image"

const Grid = styled("div")`
  margin-top: 1.5rem;
  background-color: #ffffff;
  padding: 1rem;
  display: grid;
  align-items: center;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
`

const Hr = styled("hr")`
  margin-block: 2rem;
`

const Supporters = styled("div")`
  h2 {
    text-align: center;
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query sectionSupportersQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "supporters" } }) {
        html
        frontmatter {
          images {
            image {
              id
              childImageSharp {
                fluid(quality: 95, maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
          }
        }
      }
    }
  `)

  return (
    <Supporters>
      {data.markdownRemark.html && (
        <div
          style={{textAlign: "center"}}
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
        
      )}
      <Grid>
        {data.markdownRemark.frontmatter.images && data.markdownRemark.frontmatter.images.map(image => (
          <Img fluid={image.image.childImageSharp.fluid} alt={image.alt} />
        ))}
      </Grid>
    </Supporters>
  )
}
