import React from "react"
import { Container } from "./Container"
import { useStaticQuery, graphql } from "gatsby"
import { Section } from "./Section"
import styled from "@emotion/styled"
import { mediaQueries } from "./breakpoints"
import { css } from "@emotion/react"
import Donate from './Donate'
import Supporters from './Supporters'
import BtcPay from './BtcPay'

const Grid = styled("div")`
  display: grid;
  align-items: flex-start;
  gap: 1rem 4rem;
  ${mediaQueries.medium(
    css`
      grid-template-columns: 2fr 3fr;
    `
  )}
`

const Hr = styled("hr")`
  margin-block: 4rem;
`

const Img = styled("img")`
  display: block;
  width: 100%;
  max-width: 200px;
  margin-inline: auto;
`

export default () => {
  const data = useStaticQuery(graphql`
    query sectionAboutQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "settings" } }) {
        html
      }
    }
  `)

  return (
    <Section>
      <Container about>
        <div
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
      </Container>
    </Section>
  )
}
