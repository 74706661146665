import React from "react"
import { Container } from "./Container"
import { useStaticQuery, graphql } from "gatsby"
import { Section } from "./Section"
import Img from "gatsby-image"
import { SRLWrapper } from "simple-react-lightbox";
import styled from "@emotion/styled"
import AllYears from "./AllYears"
import { mediaQueries } from "./breakpoints"
import { css } from "@emotion/react"

const Grid = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: -1rem;
  margin-right: -1rem;
  ${mediaQueries.medium(
    css`
      margin: 0;
    `
  )}
  ${mediaQueries.large(
    css`
      grid-template-columns: 1fr 1fr 1fr 1fr;
    `
  )}
`

const ImageWrapper = styled("a")`
  position: relative;
  display: block;
  height: 250px;
  overflow: hidden;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transform: scale(1);
  transition: all .2s ease-in-out;
  ${mediaQueries.large(
    css`
      height: 310px;
    `
  )}

  @media (hover: hover) {
    &:hover {
      z-index: 1;
      transform: scale(1.025);
      box-shadow: 0 0 1rem 1em rgba(0, 0, 0, 0.2);
    }
  }

  .gatsby-image-wrapper {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }
`

const options = {
  caption: {
    showCaption: false,
  },
}

export default () => {
  const data = useStaticQuery(graphql`
    query historySectionQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "settings"}}) {
        id
        frontmatter {
          images {
            image {
              id
              publicURL
              childImageSharp {
                fluid(quality: 95, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                  src
                }
              }
            }
            alt
          }
        }
      }
    }
  `)

  return (
    <Section id="historie" last>
      <Container>
        <h2>HISTORIE</h2>
        {data.markdownRemark.frontmatter.images &&
          data.markdownRemark.frontmatter.images.length ? (
            <SRLWrapper options={options}>
              <Grid>
                {data.markdownRemark.frontmatter.images.map(image => (
                  <div key={image.image.id}>
                    <ImageWrapper data-attribute="SRL" href={image.image.childImageSharp.fluid.src}>
                      <Img
                        data-attribute="SRL"
                        fluid={image.image.childImageSharp.fluid}
                        alt={image.alt}
                        max-width="400"
                      />
                    </ImageWrapper>
                  </div>
                ))}
                <AllYears />
              </Grid>
            </SRLWrapper>
          ) : null}
      </Container>
    </Section>
  )
}
